<template>
  <div class="min-h-screen flex flex-col">
    <Header class="pt-1 pb-2 sm:pb-3">
      <template #logowithtext>
        <LogoWithText/>
      </template>
    </Header>
    <main class="container mx-auto grow max-w-10xl">
      <slot/>
    </main>
    <Footer/>
  </div>
</template>
<script setup lang="ts">
</script>